// webfontkit para Klavika, fuente institucional de Cabal

// @font-face {
//     font-family: 'klavika';
//     src: url('fonts/klavika_regular_plain-webfont.woff2') format('woff2'),
//          url('fonts/klavika_regular_plain-webfont.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;

// }


@font-face {
    font-family: 'klavika';
    src: url('fonts/klavika_bold-webfont.woff2') format('woff2'),
         url('fonts/klavika_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'klavika';
    src: url('fonts/klavika_medium-webfont.woff2') format('woff2'),
         url('fonts/klavika_medium-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'klavika';
    src: url('fonts/klavika_light-webfont.woff2') format('woff2'),
         url('fonts/klavika_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}