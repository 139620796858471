/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";
// custom scss
@import "klavika";
@import "calibri";
@import "typicons";
@import "fullpage";
@import "browserupdate";
@import "cabal";


@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";

/*

@media( min-width : 700px ) {
	.ember-modal-dialog{
			width: auto;
			height: auto;
	}
		
	.ember-modal-dialog img {
		  width: auto;
		  height: auto;
	}
	#video_modal {
		width: 100%;
		height: 100%;
	}
}

@media( max-width : 699px ) {
	.ember-modal-dialog{
			width: 100%;
			height: auto;
	}
		
	.ember-modal-dialog img {
		  width: 95%;
		  height: auto;
	}
	#video_modal {
		width: 100%;
		height: 100%;
	}

}*/


@media( min-width : 700px ) {
	.ember-modal-dialog{
			width: 55%;
			height: auto;
	}
		
	.ember-modal-dialog img {
		  width: 100%;
		  height: auto;
	}
}

@media( max-width : 699px ) {
	.ember-modal-dialog{
			width: 95%;
			height: auto;
	}
		
	.ember-modal-dialog img {
		  width: 100%;
		  height: auto;
	}
}