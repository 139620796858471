// Estilos propios de cabal.coop.py
// Barra de menú
body {
	background-color: #fff;
}
p, li, span {
	// font-size: 1.4em;
}
.img-holder-img {
	height: auto !important;
}
a {
	outline: none;
}
.alert-danger{
	list-style:none;
}
.index-alert{
	font-size:10px;
}
.header-parallax {
	display: block;
	position: absolute;
	height: 500px;
	background-image: url("/assets/images/fondo.jpg");
	background-position: cover;
	background-size: cover;
	background-repeat: no-repeat;
	top: 0px;
	left: 0;
	width: 100%;
	z-index: -1;
}
.navbar.navbar-light {
	// border-top: 5px solid $celeste-demo;
	background-color: rgba($cabal-secundario, 0.8) !important;
	min-height: 80px;
	position: fixed;
	&.scrolled {
		background-color: $cabal-secundario !important;
	}
	.nav-item {
		font-size: 1rem !important;
		a {
			transition:all .5s ease 0s;
			color: #fff !important;
			font-size: 16px !important;

			text-shadow: 1px 1px 2px $cabal-primario;
			&.active, &:hover {
				font-weight: bold !important;
				font-size: 17px !important;
			}
		}
	}
	.navbar-brand img {
		width: 140px;
		@include media-breakpoint-up(sm) {
		    width: 190px;
			position: absolute;
			top: 32px;
			left: 30px;
		}
		@include media-breakpoint-down(sm) {
			left: 30px;
		}
	}
}
.linea {
	width: 50%;
	border-bottom: 1px solid rgb(100,100,100);
	margin: auto;
	padding: 5px 0;
	margin-bottom: 30px;
}
.card-staff{
	height: 100%;
}
.card-socio {
	@extend .card;
	margin-bottom: 20px;
	padding: 10px;
	min-height: 220px; 
    height:100px;
	img {
		vertical-align: middle;
		margin: auto;
		width:100%;
		min-height:30px;
	}
	.titulo {
		a:not([href]) {
			color: #fff !important;
		}
		font-size: 1.2rem;
		padding: 10px;
		font-weight: 300;
		font-family: 'klavika';
		text-transform: uppercase;
		color: #fff;
		display: block;
		text-align: center;
		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}
}
.card-socio:after,.card-socio:before {content:""}
.card-socio {
	overflow:hidden}
.card-socio .title{
	letter-spacing:1px}
.card-socio .post{
	font-style:italic}
.card-socio{
	position:relative}
.card-socio:after,.card-socio:before{
	width:100%;height:100%;background:rgba($cabal-celeste,.9);position:absolute;top:0;left:0;opacity:0;transition:all .5s ease 0s}
.card-socio:after{
	background:rgba(255,255,255,.3);border:2px solid #0dab76;top:0;left:170%;opacity:1;z-index:1;transform:skewX(45deg);transition:all 1s ease 0s}
.card-socio:hover:before{
	opacity:1}
.card-socio:hover:after{
	left:-170%}
.card-socio .box-content{
	text-decoration: none;
	color: #fff;
	&:hover {color: #fff; }
	width:100%;position:absolute;bottom:-100%;left:0;transition:all .5s ease 0s}
.card-socio:hover .box-content{
	top: 5%}
.card-socio .title{
	display:block;font-size:22px;font-weight:700;color:#fff;margin:0 0 10px}
.card-socio .post{
	display:block;font-size:15px;font-weight:600;color:#fff;margin-bottom:10px}
@media only screen and (max-width:990px){.box{margin-bottom:30px}
}


.titulo-slant {
    background-color: $cabal-primario;
    position : relative;
    display : inline-block;
    height : 100px;
    width : 314px;
    text-align : center;
    // margin : 0 auto;
}

.titulo-slant h1 {
    padding : 14px;
    font-size: 4em;
    text-align: left;
    margin-left: 14px;
}
.slant-addon {
    font-size: 4em;
    display: inline-block;
    z-index: 10;
    position: inherit;
    text-align: left;
}

.titulo-slant::after {
    content: '';
    height: 100px;
    width: 100px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    transform: skewX(45deg);
    transform-origin: top;
    -webkit-transform: skewX(45deg);
    -webkit-transform-origin: 0 0;
}

.container {
	max-width: 96% !important;
}

.boton-preguntas {
	background: transparent;
  border: 2px solid white;
  border-radius: 50%;
  padding: 7px;
  color: #fff !important;
  font-weight: bold;
  width: 45px;
  height: 45px;
}
.boton-ingresar {
	background: transparent;
	border: 2px solid white;
	border-radius: 15px;
	padding: 7px 20px;
	color: #fff !important;
	font-weight: bold;
}
.boton-ingresar, .boton-preguntas {
	&:hover, &.active {
		box-shadow: 0 0 5px 1px rgba(white, 0.7), 0 0 5px 1px rgba(white, 0.7) inset;
	}
}

.linea-footer {
	height: 10px;
	background: $cabal-primario;
	position: absolute;
	bottom: 10px;
	left: 0;
	width: 100%;
}

.textos-1 {
	@include media-breakpoint-up(sm) {
		padding: 10rem 0;
		background-image: url("/assets/images/nuevas/edificio-interior.jpg");
		background-size: cover;
	}
	background-image: url("/assets/images/edificio-interior-mobile.jpg");
	height: 100%;
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: contain;
	@include media-breakpoint-down(sm) {
		padding: 2em;
	}
	.titulos-1 {
	@include media-breakpoint-down(sm) {
		height: 0;
	}
		.titulo {
			font-weight: bold;
			font-size: 32px;
			color: $cabal-primario;
		}
		.subtitulo {
			color: $cabal-primario;
			font-size: 15px;
			font-weight: normal;
		}
		.subtitulo::after {
			content: "";
			background-image: url("/assets/images/flecha_azul.png");
			display: inline-block;
			width: 45px;
			height: 45px;
			background-size: contain;
			background-repeat: no-repeat;
			margin: 0 0 -18px 18px;
		}
	}
}
.footer {
	@include media-breakpoint-down(sm) {
		padding-top: 4em;
	}
}

$color_white: #fff;
$color_prime: #5ad67d;
$color_grey: #e2e2e2;
$color_grey_dark: #a2a2a2;

.section.entidades {
	position: relative;
}

.contenedor-descargas{
	padding-top: 40px;
}

.link-descargas a{
	font-size:25px;
	margin-right: 20px;
	text-decoration: none;
	margin-top: 10px;
	margin-bottom: 8px;
}

.descargas-titulo{
	background-color: #006398;
	padding: 20px 20px 40px 20px;
	h3{
		color: white;
	}
}

.titulo-marca{
	position: absolute;
	left: 20px;
	top: -30px;
	color: white;
}
.brand-negativo{
	position:relative;
	display: flex;
	justify-content: space-around;
	background-color: #169fdb1a;
	width: 50%;
	padding-left: 5px;
	border-bottom: 1px solid #acd8ec;
	border-right: 1px solid #acd8ec;
}
.brand-normal{
	position:relative;
	display: flex;
	justify-content: space-around;
	padding-left:5px;
	float: left;
	width:50%;
	text-align: right;
	border-bottom: 1px solid #acd8ec;
	border-left: 1px solid #acd8ec;
}
.brand-full{
	position:relative;
	display: flex;
	justify-content: space-around;
	padding-left:5px;
	float: left;
	width:100%;
	text-align: right;
	border-bottom: 1px solid #acd8ec;
	border-left: 1px solid #acd8ec;
	border-right: 1px solid #acd8ec;
}
.entidades-comercios-mobile {
	@include media-breakpoint-up(sm) { display: none; }
	.titulo {
  	font-size: 3em;
  	color: #fff;
  	font-weight: bold;
  	font-family: 'klavika';
  	text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  	z-index: 101;
  	display: block;
		position: absolute;
		top: calc(70% - 1.5em);
		width: 100%;
		text-align: center;
  }
  .boton {
  	font-size: 15px;
  	color: #fff;
  	text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  	font-weight: bold;
  	padding: 10px 15px;
  	border: 3px solid #fff;
  	border-radius: 15px;
  	z-index: 101;
  	display: block;
  	height: 48px;
  }
  .entidades {
  	position: absolute;
		height: 50%;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		background-image: url("/assets/images/nuevas/entidades.jpg");
		background-size: cover;
  }
  .comercios {
  	position: absolute;
		height: 50%;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		background-image: url("/assets/images/nuevas/comercios.jpg");
		background-size: cover;
  }
}
.download-link{
	a{
		display:block;
	}
	button{
		padding: 1px 1px;
	}
}
.entidades-comercios {
	@include media-breakpoint-down(md) { display: none; }
	height: 100%;
		img {
			height: 100%;

			width: auto;
		}
	.esq-derecha {
	position: absolute;
	width: 100%;
	height: 100%;
  -webkit-clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
	clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);	
	}
	.esq-izquierda {
		position: absolute;
		width: 100%;
		height: 100%;
	  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
	}	
  .titulo {
  	font-size: 3em;
  	color: #fff;
  	font-weight: bold;
  	font-family: 'klavika';
  	text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  	z-index: 101;
  	display: block;
  	height: 60px;
		
  }
  .boton {
  	font-size: 15px;
  	color: #fff;
  	text-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  	font-weight: bold;
  	padding: 10px 15px;
  	border: 3px solid #fff;
  	border-radius: 15px;
  	z-index: 101;
  	display: block;
  	height: 48px;

  }
  .entidades {
  	cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 0;
    .foto {background-image: url("/assets/images/nuevas/entidades.png") }
    flex-direction: row;
    max-width: 100%;
    flex-basis: 57%;
	margin-right: -7%;
    height: auto;
    a{
    	text-decoration: none;
    }
    &:hover {
    	// box-shadow: 0 0 100% rgba(255,255,255,0.1);
    	flex-basis: 70%;
  		margin-right: -20%;
  		z-index: 100;
    	transition: 0.5s;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    	width: 50% !important;
    	
    	padding-right:0px;
    	max-width: 50% !important;
    	flex-basis: 50% !important;
    	margin-right: 0 !important;
    	&:hover {
    		flex-basis: '' !important;
    		margin-right: 0 !important;
    		width: 50% !important;
    		max-width: 50% !important;
    	}
  	}
    img {
    	&:hover {
	    	box-shadow: 0 0 100% rgba(255,255,255,0.1);
	    }
    }
    .titulo {
    	position: absolute;
			right: 20%;
			top: 22%;
    }
    .boton {
	  	position: absolute;
			right: 29%;
			top: 40%;
    }
  }
  .comercios {
  	cursor: pointer;
	display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 0;
    .foto {background-image: url("/assets/images/nuevas/comercios.png") }
    flex-direction: row;
    max-width: 100%;
    height: auto;
    flex-basis: 57%;
	margin-left: -7%;
    &:hover {
    	flex-basis: 70%;
  		margin-left: -20%;
    	transition: 0.5s;
    }
    a{
    	text-decoration: none;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    	flex-basis: '' !important;
    	margin-left: 0 !important;
    	width: 50% !important;
    	max-width: 50% !important;
    	padding-left:0px;
    	&:hover {
    		flex-basis: '' !important;
    		margin-left: 0 !important;
    		width: 50% !important;
    		max-width: 50% !important;
    	}
  	}
    .titulo {
    	position: absolute;
			left: 22%;
			top: 22%;
    }
    .boton {
	  	position: absolute;
			left: 31%;
			top: 40%;
    }
  }
}

.resaltar-tab {
	background-color: white;
	border: none;
}
.resaltar-tab #tabs{
    background: #007b5e;
    color: #eee;

}
.resaltar-tab #tabs h6.section-title{
    color: #eee;
}
.resaltar-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0075bf !important;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 3px solid !important;
    font-size: 16px;
    font-weight: bold;
}
.resaltar-tab .nav-link {
	transition: color 0.8s ease;    
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #728ca9;
    font-size: 16px;
    font-weight: 600;
    border: none;
    i{
    	font-size:60px;
    }
}
.resaltar-tab .nav-link:hover {
    border: none;
}
.resaltar-tab thead{
    background: #f3f3f3;
    color: #333;
}
.resaltar-tab a{
    text-decoration: none;
    color: #333;
    font-weight: 600;
}

.mapa {
	height: 100%;
	width: 100%;
}
.formulario-contacto {
	@include media-breakpoint-down(md) {
		top: 0;
		left: 0;
		width: 100%;
		height: 100;
	}
	position: absolute;
	display: block;
	left: 100px;
	width: 300px;
	top: 130px;
	box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
	background: #fff;
	padding: 10px;
	.form-group {
		input, textarea {
			border-radius: 0;
		}
	}
}

// Estilos para ember-g-map
.g-map-canvas {
	// Ocultar en mobile
	@include media-breakpoint-down(md) {
		
	}
  width: 600px;
  height: 100%;
}
.g-map{
  height: 100%;
}
.g-map-canvas {
  width: 100%;
  height: 100%;
}

// Parallax
.slides {
	.slide{
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 75%;
			height: 45%;
			-webkit-clip-path: polygon(0 0, 0% 0, 100% 100%, 0% 100%);
			clip-path: polygon(0 0, 0% 0, 100% 100%, 0% 100%);
			@include media-breakpoint-up(sm) {
				width: 35%;
				height: 80%;
				-webkit-clip-path: polygon(0 0, 0% 0, 100% 100%, 0% 100%);
				clip-path: polygon(0 0, 0% 0, 100% 100%, 0% 100%);
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				display: none;
			}
			@supports (-ms-ime-align:auto) {
				display: none;
			}
		}
		&::after {
			content: "";
			position: absolute;
			top: 57%;
			left: 6%;
			width: 6em;
			height: 6em;
			background-size: contain;
			background-repeat: no-repeat;
			@include media-breakpoint-up(sm) {
				top: 31%;
				left: 6%;
				width: 300px;
				height: 300px;
			}
		}
		&.azul {
			&::before { background: rgba($cabal-celeste, 0.7); }
			&::after { background-image: url('images/flechas/celeste.png'); }
		}
		&.naranja {
			&::before { background: rgba($cabal-naranja, 0.7); }
			&::after { background-image: url('images/flechas/naranja.png'); }
		}
		&.verde {
			&::before { background: rgba($cabal-verde, 0.7); }
			&::after { background-image: url('images/flechas/verde.png'); }
		}
		&.celeste {
			&::before { background: rgba($cabal-primario, 0.7); }
			&::after { background-image: url('images/flechas/celeste.png'); }
		}
		background-size: cover;
		background-position: center top;
		position: relative;
		background-color: $cabal-primario;
		.texto-grande {
			font-family: 'klavika';
			position: absolute;
			top: 32%;
			right: 3%;
			width: 60%;
			text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
			@include media-breakpoint-down(md) {
				// width: 50%;
				// background: rgba(black,0.4);
				// padding: 0.2em 0.5em;
				// font-weight: 300;
				font-size: 1.2em;
				// text-transform: uppercase;
				// text-align: center;
				// text-shadow: none;
			}
			@include media-breakpoint-up(sm) {
				top: 20%;
				right: 2%;
				width: 35%;
				top: 15%;
				right: 5%;
				width: 35%;
				font-size: 3.5em;
			}
			display: block;
			line-break: normal;
			text-align: right;
			font-weight: 400;
			line-height: 1.2em;
			font-size: 1.5em;
			z-index: 10;
			color: white;
		}
		.texto-chico {
			@include media-breakpoint-down(sm) {
				font-size: 1em;
			width: 32%;
			bottom: 7%;
			}
			font-family: 'klavika';
			position: absolute;
			bottom: 6%;
			left: 5%;
			color: white;
			z-index: 10;
			width: 20%;
			font-weight: 300;
			font-size: 1.7em;
			text-shadow: 2px 2px 5px rgba(0,0,0,0.7);
		}
	}
	
	#slide1 { 
		@include media-breakpoint-down(sm) {
			background-image: url('/assets/images/slides/1-mob.jpg');
		}
		background-image: url('/assets/images/slides/1.jpg'); }
	#slide2 { 
		@include media-breakpoint-down(sm) {
			background-image: url('/assets/images/slides/2-mob.jpg');
		}
		background-image: url('/assets/images/slides/2-2.jpg'); }
	#slide3 { 
		@include media-breakpoint-down(sm) {
			background-image: url('/assets/images/slides/3-mob.jpg');
		}
		background-image: url('/assets/images/slides/3.jpg'); }
	#slide4 { 
		@include media-breakpoint-down(sm) {
			background-image: url('/assets/images/slides/4-mob.jpg');
		}
		background-image: url('/assets/images/slides/4.jpg'); }
	
		/*obarrios slides para promociones*/
		#promo1 { 
			@include media-breakpoint-down(sm) {
				background-image: url('/assets/images/slides/promociones/1.jpg');
			}
			background-image: url('/assets/images/slides/promociones/1.jpg'); }
			#promo2 { 
				@include media-breakpoint-down(sm) {
					background-image: url('/assets/images/slides/promociones/2.jpg');
				}
				background-image: url('/assets/images/slides/promociones/2.jpg'); }
				#promo3 { 
					@include media-breakpoint-down(sm) {
						background-image: url('/assets/images/slides/promociones/3.jpg');
					}
					background-image: url('/assets/images/slides/promociones/3.jpg'); }
					#promo4 { 
						@include media-breakpoint-down(sm) {
							background-image: url('/assets/images/slides/promociones/4.jpg');
						}
						background-image: url('/assets/images/slides/promociones/4.jpg'); }
						#promo5 { 
							@include media-breakpoint-down(sm) {
								background-image: url('/assets/images/slides/promociones/5.jpg');
							}
							background-image: url('/assets/images/slides/promociones/5.jpg'); }
							#promo6 { 
								@include media-breakpoint-down(sm) {
									background-image: url('/assets/images/slides/promociones/6.jpg');
								}
								background-image: url('/assets/images/slides/promociones/6.jpg'); }
								#promo7 { 
									@include media-breakpoint-down(sm) {
										background-image: url('/assets/images/slides/promociones/7.jpg');
									}
									background-image: url('/assets/images/slides/promociones/7.jpg'); }
									#promo8 { 
										@include media-breakpoint-down(sm) {
											background-image: url('/assets/images/slides/promociones/8.jpg');
										}
										background-image: url('/assets/images/slides/promociones/8.jpg'); }
										#promo9 { 
											@include media-breakpoint-down(sm) {
												background-image: url('/assets/images/slides/promociones/9.jpg');
											}
											background-image: url('/assets/images/slides/promociones/9.jpg'); }
											#promo10 { 
												@include media-breakpoint-down(sm) {
													background-image: url('/assets/images/slides/promociones/10.jpg');
												}
												background-image: url('/assets/images/slides/promociones/10.jpg'); }
												#promo11 { 
													@include media-breakpoint-down(sm) {
														background-image: url('/assets/images/slides/promociones/11.jpg');
													}
													background-image: url('/assets/images/slides/promociones/11.jpg'); }
													#promo12 { 
														@include media-breakpoint-down(sm) {
															background-image: url('/assets/images/slides/promociones/12.jpg');
														}
														background-image: url('/assets/images/slides/promociones/12.jpg'); }
														#promo13 { 
															@include media-breakpoint-down(sm) {
																background-image: url('/assets/images/slides/promociones/13.jpg');
															}
															background-image: url('/assets/images/slides/promociones/13.jpg'); }
															#promo14 { 
																@include media-breakpoint-down(sm) {
																	background-image: url('/assets/images/slides/promociones/14.jpg');
																}
																background-image: url('/assets/images/slides/promociones/14.jpg'); }
																#promo15 { 
																	@include media-breakpoint-down(sm) {
																		background-image: url('/assets/images/slides/promociones/15.jpg');
																	}
																	background-image: url('/assets/images/slides/promociones/15.jpg'); }
	/*#slide4 { 
		@include media-breakpoint-down(sm) {
			background-image: url('/assets/images/novedades/sorteo-fondo.jpg');
			background-size: contain;
			background-color: #0273c3;
			background-repeat: no-repeat;
		}
		background-position: center center !important;
		background-image: url('/assets/images/novedades/sorteo-fondo.jpg'); }*/
}

// Navbar
nav.navbar {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.pink-ribbon{
		left: 12px !important;
	}
	.navbar.navbar-light .navbar-brand img{
		left:30px;
	}
	.navbar-text.d-none.d-sm-block{
		margin-right: 0 !important;
	}
}

.header-imagen {
	width: 100%;
	height: 300px;
	background-size: cover;
	h1 {
		color: #fff;
		position: relative;
		top: 170px;
		left: 50px;
		font-weight: bold;
		font-size: 3rem;
		text-shadow: 2px 2px 0px $cabal-primario,3px 3px 3px rgba(0,0,0,0.3);
	}
	&.preguntas {
		background-image: url('/assets/images/fondo3.jpg');
	}
	&.institucional {
		background-image: url('/assets/images/edificio-cabal.jpg');
		background-position: center;
	}
	&.entidades {
		background-image: url('/assets/images/entidades3.jpg');
		background-position: center;
	}

	&.comercios {
		background-image: url('/assets/images/comercios3.jpg');
		background-position: center;
	}
}

.container-login {
	background-color: $cabal-secundario;
	background-image: url('/assets/images/nuevas/ingresar.jpg');
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.tarjetas {
	  margin-top: 20%;
	  width: 90%;
	  @include media-breakpoint-down(sm) {
	  	margin-bottom: 30px;
	  	margin-top: 90px;
		}
	  margin-left: auto;
	  margin-right: auto;
	}
}

.container-comercios {
	background-color: $cabal-secundario;
	background-image: url('/assets/images/nuevas/comercios.jpg');
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.tarjetas {
	  margin-top: 20%;
	  width: 90%;
	  @include media-breakpoint-down(sm) {
	  	margin-bottom: 30px;
	  	margin-top: 90px;
		}
	  margin-left: auto;
	  margin-right: auto;
	}
}

.container-micabal {
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.tarjetas {
	  margin-top: 15%;
	  width: 90%;
	  @include media-breakpoint-down(sm) {
	  	margin-bottom: 30px;
	  	margin-top: 90px;
		}
	  margin-left: auto;
	  margin-right: auto;
	}
}


.container-404 {
	background-color: $cabal-secundario;
	background-image: url('/assets/images/palmeras.jpg');
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.tarjetas {
	  margin-top: 20%;
	  width: 90%;
	  margin-left: auto;
	  margin-right: auto;
	}
}

.section {
	&.about {
		@include media-breakpoint-up(sm) {
			//padding-top: 80px;
		}
	}
	&.entidades {
		// background-image: url("/assets/images/fondo-entidades.jpg");
	}
}

// fill cascade para que los SVG tomen el color del padre

body {
	fill: currentColor;
}

.flecha-arriba {
	top: auto;
	left: auto;
	display: block;
	bottom: 90px;
	right: 34px;
	position: fixed;
	background-image: url("/assets/images/flecha-arriba.png");
	cursor: pointer;
	width: 50px;
	height: 50px;
	z-index: 150;
	@include media-breakpoint-up(sm) {
		bottom: 50px;
	}
}


.fp-slidesNav.fp-bottom {
	text-align: center;
	ul {
		li {
			a {
				span {
					background: #fff;
					border: 2px solid $cabal-primario;
				}
			}
		}
	}
}


.staff {
	text-align: center;
	// min-height: 269px;
	h4 { 
		font-size: 1.05em;
		font-weight: 400;
	}
	p { margin-bottom: 4px; font-size: 1rem; }
	.cargo { color: #666; text-transform: uppercase; font-size: 0.85em;}
	.foto {
		width: 140px;
		height: auto;
		margin-bottom: 5px;
		border-radius: 50%;
		border: 4px solid white;
		box-shadow: 0 0 3px 0px rgba(0,0,0,0.4);
	}
}

.acceso-cabal {
	height: 180px;
	border: none;
	a {
		h4 {
			font-size: 1.7em;
			font-weight: 300 !important;
			color: #fff !important;
			text-transform: uppercase;
			position: absolute;
			bottom: 10px;
			padding-right: 10px;
		}
		.icono-acceso {
			position: absolute;
			top: 0;
			font-size: 4.5rem;
			left: auto;
			right: 1rem;
			color: #fff;
		}
		&:hover, &:active {
			text-decoration: none;
		}
	}
	@include media-breakpoint-down(sm) {
		.icono-acceso {
			font-size: 4rem !important;
		}
		height:120px;
		margin-bottom: 10px;
		a {
			h4 {
				font-size: 1.4em;
				bottom: 0;
				padding-right: 0;
			}
		}
	}
	&.azul1 { 
		background: linear-gradient(to right, rgba($cabal-primario,1) 0%, rgba(lighten($cabal-primario,20%),1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(lighten($cabal-primario,10%),1) 0%, rgba(lighten($cabal-primario,30%),1) 100%); 
		}
	}
	&.azul2 { 
		background: linear-gradient(to right, rgba($cabal-celeste,1) 0%, rgba(lighten($cabal-celeste,20%),1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(lighten($cabal-celeste,10%),1) 0%, rgba(lighten($cabal-celeste,30%),1) 100%); 
		}
	}
	&.azul3 { 
		background: linear-gradient(to right, rgba($cabal-naranja,1) 0%, rgba(lighten($cabal-naranja,20%),1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(lighten($cabal-naranja,10%),1) 0%, rgba(lighten($cabal-naranja,30%),1) 100%); 
		}
	}
	&.azul4 { 
		background: linear-gradient(to right, rgba($cabal-verde,1) 0%, rgba(lighten($cabal-verde,20%),1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(lighten($cabal-verde,10%),1) 0%, rgba(lighten($cabal-verde,30%),1) 100%); 
		}
	}
}	




.titulo-seccion {
	width: 100%;
	border-bottom: 3px solid;
}

.col-md-25 {
	@extend .col-md-3;
	width: 20%;
	max-width: 20%;
	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 100%;
	}
}

$color-uruguay: #0038a7;
$color-argentina: #73a3e1;
$color-brasil: #00ac3d;


.link-region {
	height: 150px;
	border: none;
	margin-bottom: 40px;
	a {
		h4 {
			font-size: 32px;
			font-weight: 300 !important;
			color: #fff !important;
			text-transform: uppercase;
			position: absolute;
			bottom: 10px;
			padding-right: 10px;
		}
		&:hover, &:active {
			text-decoration: none;
		}
	}
	&.argentina { 
		background: linear-gradient(to right, rgba(darken($color-argentina,20%),1) 0%, rgba($color-argentina,1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(darken($color-argentina,10%),1) 0%, rgba(lighten($color-argentina,10%),1) 100%); 
		}
	}
	&.brasil { 
		background: linear-gradient(to right, rgba($color-brasil,1) 0%, rgba(lighten($color-brasil,20%),1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(lighten($color-brasil,10%),1) 0%, rgba(lighten($color-brasil,30%),1) 100%); 
		}
	}
	&.uruguay { 
		background: linear-gradient(to right, rgba($color-uruguay,1) 0%, rgba(lighten($color-uruguay,20%),1) 100%); 
		&:hover {
			background: linear-gradient(to right, rgba(lighten($color-uruguay,10%),1) 0%, rgba(lighten($color-uruguay,30%),1) 100%); 
		}
	}
}	



.error-page {
		background-size: cover;
		background-image: url('images/comprando2.jpg'); 
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 33%;
			height: 80%;
			-webkit-clip-path: polygon(0 0, 0% 0, 100% 100%, 0% 100%);
			clip-path: polygon(0 0, 0% 0, 100% 100%, 0% 100%);
			background: rgba($cabal-naranja, 0.7);
		}
		&::after {
			content: "";
			position: absolute;
			top: 30%;
			left: 6%;
			width: 300px;
			height: 300px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('images/flechas/naranja.png'); 
		}
		.texto-grande {
			font-family: 'klavika';
			position: absolute;
			top: 40%;
			right: 31%;
			width: 70%;
			display: block;
			line-break: normal;
			text-align: right;
			font-weight: 400;
			line-height: 1.2em;
			font-size: 3.5em;
			z-index: 10;
			color: #333;
			text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
		}
}


.spinner {
  margin: 30px auto 30px;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: $cabal-celeste;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.img-button {
	cursor: pointer;
	border: 3px solid rgba(0,0,0,0);
	filter: grayscale(0.7);
	&.selected {
		filter: grayscale(0);
		// border: 3px solid rgba($cabal-celeste, 0.5);
		box-shadow: 0 0 7px 2px rgba($cabal-celeste, 1);
		border: 3px solid rgba($cabal-celeste, 0.7);
	}
	&:hover {
		filter: grayscale(0);
		box-shadow: 0 0 7px 2px rgba($cabal-celeste, 1);
	}
}
.pregunta {
	.titulo-pregunta {
		font-size: 1.3em;
		color: rgba(0,0,0,0.7);
	}
	.respuesta-pregunta {
    padding-left: 10px;
    border-left: 5px solid rgba($cabal-celeste,0.5);
    margin-left: 10px;
	}
	&:hover {
		.titulo-pregunta {
			color: $cabal-primario;
		}
		.respuesta-pregunta {
 			border-left: 5px solid rgba($cabal-celeste,0.9);
		}
	}
}

form.needs-validation {
	// .cabal-label { display: none; }
	.cabal-label {
		display: block;
		margin-bottom: 0;
		font-size: 0.85em;
		font-weight: bold;
		letter-spacing: 1px;
		color: $cabal-primario;
		transition: all 0.5s;
	}
	.cabal-label-no-input {
			display: block;
			margin-bottom: 0;
			font-size: 0.85em;
			font-weight: bold;
			letter-spacing: 1px;
			color: $cabal-primario;
			transition: all 0.5s;
		}
}

.a-pink {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.pink-ribbon {
	@include media-breakpoint-down(sm) {
		display: none;
	}
  position: absolute;
  top: 30px;
  left: 60px;
  height: 72px;
}
.imageHolder {
	&::after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 10px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
    left: 0;
    margin-top: -10px;
	}
}


.formulario-contacto .form-group {
    margin-bottom: 5px;
}
@include media-breakpoint-up(lg) {
		.contenedor-formulario-contacto{
			width: 60%
		}
	}

@include media-breakpoint-down(lg) {
		.contenedor-formulario-contacto{
			width: 60%
		}
	}

@include media-breakpoint-down(md) {
		.contenedor-formulario-contacto{
			width: 80%
		}
	}
@include media-breakpoint-down(sm) {
		.contenedor-formulario-contacto{
			width: 100%
		}
	}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	font-weight: bold;
	color: $cabal-primario;
}

.boton-acceso {
	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 16px;
		right: -14px;
		left: auto;
	}
}

.boton-acceso1 {
	@include media-breakpoint-down(sm) {
		position: absolute;
		top: -15px;
		right: -14px;
		left: auto;
	}
}
/*
.boton-acceso2 {
	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 32px;
		right: -25px;
		left: auto;
		bottom: auto;
	}
}

.boton-acceso3 {
	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 80px;
		right: -25px;
		left: auto;
	}
} 
*/

@media( max-width : 321px) {
	.boton-acceso2 {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 115px;
			right: 140px;
			left: auto;
			padding: 1px;
		}
	}

	.boton-acceso3 {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 115px;
			right: 6px;
			left: auto;
			padding: 1px;
		}
	} 
}


@media( min-width : 360px ) {
	.boton-acceso2 {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 115px;
			right: 155px;
			left: auto;
			padding: 0px 5px 0px 0px;
		}
	}

	.boton-acceso3 {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 115px;
			right: 6px;
			left: auto;
			padding: 0px 5px 0px 0px;
		}
	} 
}

@media( min-width : 450px ) {
	.boton-acceso2 {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 115px;
			right: 190px;
			left: auto;
			padding: 0px 5px 0px 0px;
		}
	}

	.boton-acceso3 {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 115px;
			right: 6px;
			left: auto;
			padding: 0px 5px 0px 0px;
		}
	} 
}


.dos-em {
	font-size: 2em;
}

a.card:hover {
	text-decoration: none !important;
}

.wizard {
    margin: 20px auto;
    background: #fff;
}

    .wizard .nav-tabs {
        position: relative;
        margin: 40px auto;
        margin-bottom: 0;
        border-bottom-color: #e0e0e0;
    }

    .wizard > div.wizard-inner {
        position: relative;
    }

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
}
span.round-tab i{
    color:#555555;
}
.wizard li.active span.round-tab {
    background: #fff;
    border: 2px solid $cabal-secundario;
    
}
.wizard li.active span.round-tab i{
    color: $cabal-secundario;
}

span.round-tab:hover {
    color: #333;
    border: 2px solid #333;
}

.wizard .nav-tabs > li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: $cabal-secundario;
    transition: 0.1s ease-in-out;
}

.wizard li.active:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 1;
    margin: 0 auto;
    bottom: 0px;
    border: 10px solid transparent;
    border-bottom-color: $cabal-secundario;
}

.wizard .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
}

    .wizard .nav-tabs > li a:hover {
        background: transparent;
    }

.wizard .tab-pane {
    position: relative;
    padding-top: 50px;
}

.wizard h3 {
    margin-top: 0;
}

@media( max-width : 585px ) {

    .wizard {
        width: 90%;
        height: auto !important;
    }

    span.round-tab {
        font-size: 16px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard li.active:after {
        content: " ";
        position: absolute;
        left: 35%;
    }
}

input:required:invalid + .required-input-msg::after {
    position: absolute;
    content: "* campo requerido";
    font-size: 0.7rem;
    position: absolute;
    right: 20px;
    color: rgb(170,0,0);
    top: auto;
    bottom: auto;
}

[class*="hint--"]::after {
	font-size: 18px !important;
	line-height: 22px !important;
}
.strong {
	font-weight: bold;
}
.cp-Panels {
    display: block;
    width: 100%;
}
.cp-Panel {
    border: 1px solid #ddd;
}
.cp-Panel-toggle:link {
    text-decoration: none;
}
.cp-Panel-toggle {
    display: block;
    padding: 0.5rem 1rem;
    p {
    	margin: 0;
    }
}
.cp-Panel-body.cp-is-open {
  padding: 0.5rem 1rem;
}
.cp-Panel-toggle.cp-is-open {
  border-bottom: 1px solid #ccc;
}

.descripcion-micabal {
  color:white;
}
.mt-margin-add-boton{
	margin-bottom:0.5rem !important;
}

.bg-blur_accesos1 {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  //border: 3px solid #f1f1f1;
  position: absolute;
  top: 15%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  //padding: 20px;
  text-align: center;
}

@media( min-width : 585px ) {
	.bg-blur_accesos {
	  background-color: rgb(0,0,0); /* Fallback color */
	  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
	  color: white;
	  font-weight: bold;
	  //border: 3px solid #f1f1f1;
	  position: absolute;
	  top: 50%;
	  left: 30%;
	  padding: 20px;
	  transform: translate(-50%, -50%);
	  z-index: 2;
	  //padding: 20px;
	  text-align: center;
	}
	.bg-blur_accesos_video1{
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		font-weight: bold;
		position: absolute;
		top: 45%;
		left: 75%;
		padding: 20px;
		transform: translate(-50%, -90%);
		z-index: 2;
		text-align: center;
		//border: solid 10px;
		//border-color: rgba(0, 0, 0, 0.4);
	  }
	.bg-blur_accesos_video2{
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		font-weight: bold;
		position: absolute;
		top: 55%;
		left: 75%;
		padding: 20px;
		transform: translate(-50%, -10%);
		z-index: 2;
		text-align: center;
		//border: solid 10px;
		//border-color: rgba(0, 0, 0, 0.4);
	  }

	.bg-blur_accesos-sin-add {
	  background-color: rgb(0,0,0); /* Fallback color */
	  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
	  color: white;
	  font-weight: bold;
	  //border: 3px solid #f1f1f1;
	  position: absolute;
	  top: 50%;
	  left: 30%;
	  padding: 20px;
	  transform: translate(-50%, -50%);
	  z-index: 2;
	  //padding: 20px;
	  text-align: center;
	}
	.texto_acceso_visibilidad_celular{
		display:none;
	}
}

/*
@media( max-width : 584px ) {
	.bg-blur_accesosffffff {
	  background-color: rgb(0,0,0); // Fallback color 
	  background-color: rgba(0,0,0, 0.4); // Black w/opacity/see-through 
	  color: white;
	  font-weight: bold;
	  //border: 3px solid #f1f1f1;
	  position: absolute;
	  padding: 30px;
	  z-index: 2;
	  width: 100%;
	  //padding: 20px;
	  text-align: center;
	}
	.bg-blur_accesos-sin-addffff {
	  background-color: rgb(0,0,0); // Fallback color 
	  background-color: rgba(0,0,0, 0.4); // Black w/opacity/see-through
	  color: white;
	  font-weight: bold;
	  //border: 3px solid #f1f1f1;
	  position: absolute;
	  z-index: 2;
	  width: 100%;
	  padding: 5px;
	  text-align: center;
	}
}*/


@media( max-width : 584px ) {
	.bg-blur_accesos {
	  position: absolute;
	  z-index: 2;
	  width: 80%;
	  padding: 0px;
	  text-align: left;
	}
	.bg-blur_accesos_video1{
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		font-weight: bold;
		position: absolute;
		z-index: 2;
		width: 80%;
		padding: 0px;
		text-align: left;
		//border: solid 10px;
		//border-color: rgba(0, 0, 0, 0.4);
	  }
	.bg-blur_accesos_video2{
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		font-weight: bold;
		position: absolute;
		top: 55%;
		left: 75%;
		padding: 20px;
		transform: translate(-50%, -10%);
		z-index: 2;
		text-align: center;
		//border: solid 10px;
		//border-color: rgba(0, 0, 0, 0.4);
	  }
	.bg-blur_accesos-sin-add {
	  position: absolute;
	  padding: 0px;
	  z-index: 2;
	  width: 80%;
	  text-align: left;
	}
	.texto_acceso_visibilidad{
		display:none;
	}
}

.bg-blur_imagen {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
}


