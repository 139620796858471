@font-face { font-family: "Calibri";
src: url("fonts/Calibri-Bold.eot");
src: url("fonts/Calibri-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/Calibri-Bold.woff2") format("woff2"), url("fonts/Calibri-Bold.woff") format("woff"), url("fonts/Calibri-Bold.ttf") format("truetype");
font-weight: bold;
font-style: normal;
}
@font-face { font-family: "Calibri";
src: url("fonts/Calibri-BoldItalic.eot");
src: url("fonts/Calibri-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/Calibri-BoldItalic.woff2") format("woff2"), url("fonts/Calibri-BoldItalic.woff") format("woff"), url("fonts/Calibri-BoldItalic.ttf") format("truetype");
font-weight: bold;
font-style: italic;
}
@font-face { font-family: "Calibri";
src: url("fonts/Calibri-Italic.eot");
src: url("fonts/Calibri-Italic.eot?#iefix") format("embedded-opentype"), url("fonts/Calibri-Italic.woff2") format("woff2"), url("fonts/Calibri-Italic.woff") format("woff"), url("fonts/Calibri-Italic.ttf") format("truetype");
font-weight: normal;
font-style: italic;
}
@font-face { font-family: "Calibri";
src: url("fonts/Calibri.eot");
src: url("fonts/Calibri.eot?#iefix") format("embedded-opentype"), url("fonts/Calibri.woff2") format("woff2"), url("fonts/Calibri.woff") format("woff"), url("fonts/Calibri.ttf") format("truetype");
font-weight: normal;
font-style: normal;
}
